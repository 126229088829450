<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th
							class="border-top-0 text-left">
							{{ translate('payment_method') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('subtotal') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('sales_percent') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('duties') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('total_sales') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('refunds') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('expenses') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('net_sales') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="text-left">
							{{ translate(`sales_${item.attributes.payment_method}`) }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_subtotal }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_subtotal_perc }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_shipping }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_tax }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_total }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_refund }}
						</td>
						<td class="text-right">
							{{ item.attributes.expenses }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_net_sales }}
						</td>
					</tr>
					<tr class="text-right">
						<td>
							<b />
						</td>
						<td class="text-right">
							<b>{{ totals.base_subtotal }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.base_subtotal_perc }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.base_shipping }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.base_tax }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.base_total }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.base_refund }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.expenses }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.total }}</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>
import {
	MDY_FORMAT as mdyFormat,
} from '@/settings/Dates';
import {
	Report, Grids, SalesPaymentMethods,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import Sales from '@/util/Report';

export default {
	name: 'SalesPaymentMethod',
	messages: [Report, Grids, SalesPaymentMethods],
	mixins: [FiltersParams],
	data() {
		return {
			sales: new Sales(),
		};
	},
	computed: {
		loading() {
			return !!this.sales.data.loading;
		},
		errors() {
			return this.sales.data.errors;
		},
		data() {
			try {
				const { data } = this.sales.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		totals() {
			try {
				const { totals } = this.sales.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		this.$parent.$parent.$parent.rangeStart = '';
		this.$parent.$parent.$parent.rangeEnd = '';
		this.sales.getSalesByPaymentMethod().then(() => {
			const { start, end } = this.sales.data.response.data.meta.period_dates;
			this.$parent.$parent.$parent.rangeStart = this.$moment(start.date).format(mdyFormat);
			this.$parent.$parent.$parent.rangeEnd = this.$moment(end.date).format(mdyFormat);
		});
	},
	methods: {
		getDataFiltered() {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			this.sales.getSalesByPaymentMethod(options);
		},
	},
};
</script>

<style>
	.division-right {
		border-right: solid 1px #ccc;
	}
</style>
